import { isFunction } from 'lodash-es';
import React from 'react';
import type { ListBoxProps } from 'react-aria-components';
import { ListBox } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { listBox } from '../../electrons/list-box';

export type _ListBoxProps<T extends object> = ListBoxProps<T>;

function _ListBoxBase<T extends object>(
  { children, className, ...props }: _ListBoxProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <ListBox
      className={(bag) => twMerge(listBox(), isFunction(className) ? className(bag) : className)}
      ref={ref}
      {...props}>
      {children}
    </ListBox>
  );
}

const _ListBox = React.forwardRef(_ListBoxBase) as <T extends object>(
  props: _ListBoxProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof _ListBoxBase>;

export { _ListBox as ListBox };
